<template>
  <div>
    <vs-popup :active.sync="popupActive" :title="$t('Create Transaction')">
      <vs-row class="mb-2">
        <vs-col class="mb-2 px-3" vs-w="6" vs-s="6" vs-xs="12">
          <my-select     autocomplete        :placeholder="$t('Select Account')"
            class="selectExample mt-5 pt-1 w-full"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="transactionData.transaction_to"
          >
            <vs-select-item :key="index" :value="item.id" :text="item.first_name+ ' ' + item.last_name"
                            v-for="(item,index) in accounts"/>
          </my-select>
          <vs-alert color="danger" v-show="errors.transaction_to" v-if="errors.transaction_to" active="true">
            {{$t(errors.transaction_to[0])}}
          </vs-alert>
        </vs-col>
        <vs-col class="mb-2 px-3 flex" vs-w="6" vs-s="6" vs-xs="12">
          <my-select      autocomplete       :placeholder="$t('Select Category')"
            class="selectExample mt-5 pt-1 w-full"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            @change="checkSubCategory"
            v-model="transactionData.transaction_category_id"
          >
            <vs-select-item :key="index" :value="item.id" :text="item.name"
                            v-for="(item,index) in transactionCategories"/>
          </my-select>
          <vs-alert color="danger" v-show="errors.transaction_category_id" v-if="errors.transaction_category_id" active="true">
            {{$t(errors.transaction_category_id[0])}}
          </vs-alert>
          <font-awesome-icon @click="popupActive=false;openAddCategory=true;;" class="mt-10 ml-2 cursor"
            color="rgb(var(--vs-primary))" :icon="['fas', 'plus']" />
          <actions-popup v-if="openAddCategory" @close-popup="addCategory" isCategory="yes" />
        </vs-col>
        <vs-col v-if="transactionData.transaction_category_id" class="mb-2 px-3 flex" vs-w="6" vs-s="6" vs-xs="12">
          <my-select      autocomplete       :placeholder="$t('Select Sub Category')"
            class="selectExample mt-5 pt-1 w-full"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="sub_id"
          >
            <vs-select-item :key="index" :value="item.id" :text="item.name"
                            v-for="(item,index) in subcategories"/>
          </my-select>
          <vs-alert color="danger" v-show="errors.transaction_category_id" v-if="errors.transaction_category_id" active="true">
            {{$t(errors.transaction_category_id[0])}}

          </vs-alert>
            <font-awesome-icon @click="popupActive=false;openAddSubCategory=true;"
          class="mt-7 ml-2 cursor"
            color="rgb(var(--vs-primary))" :icon="['fas', 'plus']" />
          <actions-popup v-if="openAddSubCategory" @close-popup="addCategory"
            :parent="transactionData.transaction_category_id" />
        </vs-col>
      </vs-row>
      <vs-row class="mb-2">
        <vs-col class="mb-2 px-3" vs-w="6" vs-s="6" vs-xs="12">
            <vs-input class="w-full" type="datetime-local" color="success" :label="$t('Transaction Date')" v-model="transactionData.issue_date"/>
            <vs-alert color="danger" v-show="errors.issue_date" v-if="errors.issue_date" active="true">
              {{$t(errors.issue_date[0])}}
            </vs-alert>
        </vs-col>
        <vs-col class="mb-2 px-3" vs-w="6" vs-s="6" vs-xs="12">
          <span class="ml-4">{{$t('Amount')}}</span>
          <vs-input class="w-full" v-currency="{currency: {suffix:' TL'}, locale: 'en',allowNegative:false,  valueRange: { min: 0 },distractionFree: false,}" v-model="transactionData.amount"/>
          <vs-alert color="danger" v-show="errors.amount" v-if="errors.amount" active="true">
            {{$t(errors.amount[0])}}
          </vs-alert>
        </vs-col>
      </vs-row>
      <vs-row class="mb-2">
        <vs-col class="mb-2 px-3" vs-w="6" vs-s="6" vs-xs="12">
          <vs-checkbox v-model="transactionData.is_deserved">{{$t('isDeserved')}}</vs-checkbox>
          <vs-alert color="danger" v-show="errors.is_deserved" v-if="errors.is_deserved" active="true">
            {{$t(errors.is_deserved[0])}}
          </vs-alert>
        </vs-col>
      </vs-row>
      <vs-row class="mb-2">
        <vs-col class="mb-2 px-3" vs-w="12">
          <vs-textarea class="w-full" v-model="transactionData.description" height="120px"/>
          <vs-alert color="danger" v-show="errors.description" v-if="errors.description" active="true">
            {{$t(errors.description[0])}}
          </vs-alert>
        </vs-col>
      </vs-row>
      <vs-row class="mb-2" vs-type="flex" vs-w="12">
        <vs-col class="mb-2 px-3" vs-w="6" vs-s="6" vs-xs="12" vs-type="flex" vs-justify="flex-start">
          <vs-button type="gradient" color="danger" @click="popupActive=false">{{$t('Cancel')}}</vs-button>
        </vs-col>
        <vs-col class="mb-2 px-3" vs-w="6" vs-s="6" vs-xs="12" vs-type="flex" vs-justify="flex-end">
          <vs-button type="gradient" color="success" @click="makeTransaction">{{$t('Create Transaction')}}</vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import MySelect from '../../../components/MySelect'
import ActionsPopup from '@/views/pages/transactionCategory/actionsPopup'

export default {
  /* eslint-disable */
  components:{vSelect,MySelect,ActionsPopup},
  name: "NewTransactionPopup",
  data(){
    return{
      popupActive:true,
      transactionData:{
        transaction_to:'',
        transaction_category_id:'',
        issue_date: new Date().toJSON().slice(0, 10).replace(/-/g, '-')+'T'+
                    ( new Date().getHours()>9 ? new Date().getHours() : '0'+
                      new Date().getHours() ) +':'+
                    ( new Date().getMinutes()> 9 ?
                      new Date().getMinutes() : '0'+new Date().getMinutes()) ,
        amount:'',
        is_deserved:false,
        description:''
      },
      subcategories:[],
      sub_id:null,
      cashierId:'',
      errors:[],
      openAddCategory: false,
      process: false,
      openAddSubCategory: false
    }
  },
  watch:{
    popupActive(val){
      if(!val && !this.openAddCategory && !this.openAddSubCategory){
        setTimeout(()=>{
          this.$emit('closeTopup',false)
        },1)
      }
    },
    transactionCategories(val) {
        this.checkSubCategory()
      }
  },
  computed:{
    accounts(){
      return this.$store.getters["transaction/accounts"]
    },
    transactionCategories(){
      return this.$store.getters["transaction/transactionCategories"]
    }
  }
  ,methods:{
     addCategory() {
          this.getData()
          this.openAddCategory = false
          this.openAddSubCategory = false
          this.popupActive=true


      },
    checkSubCategory(){
      if(this.transactionData.transaction_category_id){
        this.subcategories = this.transactionCategories.find(x=>x.id===this.transactionData.transaction_category_id).parents
        this.sub_id=null
      }
    },
    getData(){
      this.cashierId = this.$route.params.cashierId
      this.$store.dispatch("transaction/getAccounts")
          this.$store.dispatch("account/GetData")
      this.$store.dispatch("transaction/getTransactionCategories")
    },
    makeTransaction(){
      this.transactionData.amount = this.transactionData.amount.replace(',','').replace(' TL', '')
      let finalData = {...this.transactionData}
      if(this.sub_id){
        finalData.transaction_category_id=this.sub_id
      }
      this.$store.dispatch("transaction/storeTransaction", {'id':this.cashierId,'data':finalData}).then(()=>{
        this.popupActive=false
        this.getData()
        this.$emit('success',true)
      }).catch((error)=>{
        if(error.response.data.errors)
          this.errors = error.response.data.errors
      })
    }
  },
  created() {
    this.getData()
    // this.$store.dispatch("transactionCategory/GetData")
  }
}
</script>

<style scoped>
  .cursor {
    cursor: pointer;
  }

  .mt-7 {
    margin-top: 2.0rem !important;
  }

</style>
